import React from 'react';
import Header from '../Component/Header';
import Contant from '../Component/Contant';

const Home = () => {
  return (
    <>
    <Header/> 
    <Contant/>
    </>
  )
}

export default Home